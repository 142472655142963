<template>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <b-card title="General">
        <validation-observer
          ref="generalSettingsForm"
          #default="{invalid}"
        >
          <b-form
            class="mt-2"
            @submit.prevent="submitForm"
          >
            <b-row v-if="formData">
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Church Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="church_name"
                    name="Church name"
                    vid="church_name"
                    rules="required"
                  >
                    <b-form-input
                      ref="church_name_input"
                      v-model.trim="formData.general.church_name"
                      placeholder="Church Name"
                      name="church_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Church Site URL"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="church_site_url"
                    name="Church Site URL"
                    vid="church_site_url"
                    rules="url"
                  >
                    <b-form-input
                      v-model.trim="formData.general.church_site_url"
                      placeholder="Church Site URL"
                      name="church_site_url"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12">
                <div class="h4 pt-2 mb-2">
                  Communication
                </div>
              </b-col>
              <b-col
                sm="12"
                class="mb-0"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="E-mail Address"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="email_from"
                    name="Email address"
                    vid="email_from"
                    rules="required|email"
                    mode="lazy"
                  >
                    <b-form-input
                      v-model.trim="formData.general.email_from"
                      name="email_from"
                      placeholder="E-mail"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <template v-if="showEmailAlerts">
                      <b-alert
                        variant="warning"
                        :show="isEmailConfirmed === null"
                        class="mt-1"
                      >
                        <h4 class="alert-heading">
                          We can't use this e-mail as a sender
                        </h4>
                        <div class="alert-body">
                          <span>It might be due to it's a public e-mail like gmail.com or some because some other reason, we can't use this e-mail as a sender e-mail</span>
                          <hr>
                          <span>Change e-mail and save settings to update</span>
                        </div>
                      </b-alert>
                      <b-alert
                        variant="primary"
                        :show="!isEmailConfirmed"
                        class="mt-1"
                      >
                        <h4 class="alert-heading">
                          We are waiting for your e-mail confirmation
                        </h4>
                        <div class="alert-body no-padding">
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                            type="reset"
                            class="full-width resendConfirmation"
                            :show="!isEmailConfirmed"
                            size="sm"
                            @click.prevent="resendConfirmationEmail"
                          >
                            Re-send confirmation email
                          </b-button>
                        </div>
                      </b-alert>

                      <b-alert
                        variant="success"
                        :show="isEmailConfirmed"
                        class="mt-1"
                      >
                        <h4 class="alert-heading">
                          Your e-mail was confirmed
                        </h4>
                        <div class="alert-body">
                          <span>We will send every communication through this e-mail address</span>
                        </div>
                      </b-alert>
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" />
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="E-mail From Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="email_from_name"
                    name="E-mail from name"
                    vid="email_from_name"
                    rules="required"
                  >
                    <b-form-input
                      v-model.trim="formData.general.email_from_name"
                      name="email_from_name"
                      placeholder="e.g. Downtown Church"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Your phone number"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="sms_to_number"
                    name="Phone number"
                    vid="sms_to_number"
                    rules=""
                  >
                    <b-form-input
                      v-model="formData.general.sms_to_number"
                      name="sms_to_number"
                      placeholder="202-555-0147"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  ref="timezone"
                  name="timezone"
                  vid="timezone"
                  rules="required"
                >
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    content-cols-sm
                    content-cols-lg="8"
                    label="Pick a timezone"
                  >
                    <v-select
                      v-model="formData.general.timezone"
                      label="Timezone"
                      :options="timezoneList"
                      :class="[{'hasErrors' : errors.length > 0}]"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>
            <b-card-footer class="no-padding mt-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-between"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    type="reset"
                    class="mt-2"
                    @click.prevent="resetForm"
                  >
                    Reset
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2"
                    :disabled="invalid || isSubmitting"
                    type="submit"
                  >
                    Save changes
                  </b-button>

                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCardFooter, BFormCheckbox, BAlert, BProgressBar, BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { handleValidationError } from '@/utils/helpers'
import { listTimeZones } from 'timezone-support'

export default {
  components: {
    vSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BFormCheckbox,
    BAlert,
    BProgressBar,
    BProgress,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSubmitting: false,
      formData: null,
      required,
      email,
      isEmailConfirmed: null,
      showEmailAlerts: false,
      timezoneList: [],
    }
  },
  computed: {},
  created() {
    const self = this
    const user = store.getters['auth/authUser']
    this.resetForm()
    if (user.account.postmark_signature_id !== null) {
      AccountService.getPostmarkSenderSignatureStatus()
        .then(response => {
          // get user from store (if null, not verify true/false)
          self.isEmailConfirmed = response.data.data
          self.showEmailAlerts = true
        })
    } else {
      self.showEmailAlerts = true
    }
    this.timezoneList = listTimeZones()
  },
  mounted() {
  },
  methods: {
    async submitForm() {
      const self = this
      this.$refs.generalSettingsForm.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const data = {
              general: {
                church_name: this.formData.general.church_name,
                email_from: this.formData.general.email_from,
                email_from_name: this.formData.general.email_from_name,
                sms_to_number: this.formData.general.sms_to_number,
                timezone: this.formData.general.timezone,
                church_site_url: this.formData.general.church_site_url,
              },
            }
            try {
              await AccountService.updateAccountSettings(data)
                .then(response => {
                  store.dispatch('auth/setAuthUser', response.data.data).then(() => {
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Success',
                        text: 'Settings saved',
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                    })
                    this.$router.push({ name: 'home' })
                  })
                })
                .catch(error => {
                  handleValidationError(error, this)
                })
            } catch (error) {
              // API-related errors will be shown by the API error handler
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
    async resendConfirmationEmail() {
      try {
        await AccountService.resendSenderSignatureEmail()
          .then(response => response.data).then(response => {
            if (response.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  text: 'New confirmation has been sent. If you don\'t get it, please check your spam folder',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: `There was a problem verifying this email address: ${response.error}`,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            }
          })
          .catch(error => {
            handleValidationError(error, this)
          })
      } catch (error) {
        // API-related errors will be shown by the API error handler
        console.error(error)
      } finally {
        this.isSubmitting = false
      }
    },
    resetForm() {
      this.formData = { general: { ...store.getters['auth/authUser'].account.settings.general } }
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.no-padding {
  padding: 0;
}

.v-select.hasErrors [role="combobox"] {
  border-color: #ea5455 !important;
}

.v-select.hasErrors [role="combobox"].vs__dropdown-toggle .vs__selected {
  color: #ea5455;
}

.v-select.hasErrors .vs__clear svg {
  fill: #ea5455;
}

[dir] .alert-primary .alert-heading {
  box-shadow: none;
  background: #FFF;
  border-bottom: 1px solid #eeedfd;
  font-weight: 500;
  font-size: 0.85rem;
}

[dir] .alert-primary {
  background: #FFF;
  border: 1px solid #eeedfd;
  color: #83808e;
}

[dir] .alert-primary {
  background: rgb(115 103 240 / 4%) !important;
}

[dir] .alert-primary button {
  font-weight: 400;
  border: none !important;
  text-decoration: underline;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

[dir] .alert-primary button:hover {
  background: transparent;
}

[dir] .progress-bar.bg-primary {
  background-color: #a9a2f6 !important;
}

button.resendConfirmation {
  font-size: 0.85rem;
}

[dir] .alert-warning .alert-heading {
  box-shadow: none;
  background: #FFF;
  font-weight: 500;
  font-size: 0.85rem;
  border-bottom: 1px solid #fef3e8;
}

[dir] .alert-warning {
  background: #fef3e840 !important;
  border: 1px solid #fef3e8;
  font-size: 0.85rem;
  font-weight: 400;
  color: #83808e;
}

[dir] .alert-success .alert-heading {
  box-shadow: none;
  background: #FFF;
  font-weight: 500;
  font-size: 0.85rem;
}

[dir] .alert-success {
  background: rgb(40 199 111 / 7%) !important;
  border: 1px solid #e5f8ed;
  font-size: 0.85rem;
  font-weight: 400;
  color: #83808e;
}

[dir] .alert .alert-body {
  color: #83808e;
}

[dir] .alert-warning hr {
  border-top-color: #e4e4e4;
}
</style>
